<template>
  <div class="mode-wrap" ref="modeWrap">
    <div class="mask"></div>
    <div class="dialog">
      <div class="header">
        <div class="title-wrap">
          <span class="title">{{ title }}</span>
          <span class="sub-title">{{ subTitle }}</span>
        </div>
        <img
          class="close-icon"
          src="@/assets/images/icon/close@2x.png"
          alt=""
          @click="close"
        />
      </div>
      <div class="content">
        <div class="item-wrap">
          <div class="item">
            <span class="item-title">公司名称</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input1"
                v-focusblur
                v-model="formData.companyName"
              />
              <img
                v-show="formData.companyName"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.companyName = ''"
              />
            </div>
          </div>
          <div class="item">
            <span class="item-title">您的职位</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input1"
                v-focusblur
                v-model="formData.position"
                placeholder="请输入您的职位"
              />
              <img
                v-show="formData.position"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.position = ''"
              />
            </div>
          </div>

          <div class="item">
            <span class="item-title">您的姓名</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input1"
                v-focusblur
                v-model="formData.applicant"
                placeholder="请输入您的姓名"
              />
              <img
                v-show="formData.applicant"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.applicant = ''"
              />
            </div>
          </div>

          <div class="item">
            <span class="item-title">手机号码</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input1"
                v-focusblur
                v-model="formData.phone"
                placeholder="请输入手机号码"
              />
              <img
                v-show="formData.phone"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.phone = ''"
              />
            </div>
          </div>

          <div class="item">
            <span class="item-title">验证码</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input2"
                v-focusblur
                v-model="formData.code"
                placeholder="请输入验证码"
              />
              <img
                v-show="formData.code"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.code = ''"
              />
            </div>
            <span
              class="ver-code"
              :class="{
                'no-hover': timer !== 0,
                'light-ver-code': phoneRule(formData.phone) && timer === 0,
              }"
              @click="getVerCode"
              >{{ hintTxt }}</span
            >
          </div>

          <div class="item">
            <span class="item-title">企业邮箱</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input1"
                v-focusblur
                v-model="formData.eMail"
                placeholder="请输入企业邮箱"
              />
              <img
                v-show="formData.eMail"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.eMail = ''"
              />
            </div>
          </div>

          <div class="item">
            <span class="item-title no-item-title">微信号码</span>
            <div class="input-wrap">
              <input
                type="text"
                class="input1"
                v-focusblur
                v-model="formData.wx"
                placeholder="请输入微信号码"
              />
              <img
                v-show="formData.wx"
                src="@/assets/images/icon/icon_clear.png"
                alt=""
                class="close-input-icon"
                @click="formData.wx = ''"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <span class="cancel cancel-btn" @click="close">取消</span>
        <span class="ok main-btn" @click="brandClaim">确定</span>
      </div>
    </div>
  </div>
</template>
<script>
import { phoneReg, emailReg } from "@/assets/javascript/utils";
import { User } from "@/service";

export default {
  name: "brandClaimForm",
  props: {
    tempData: {
      type: Object,
      required: true,
      default: () => {
        return {
          applicant: "刘敏",
          applyType: 1,
          auditTime: "0001-01-01 08:05:43",
          companyName: "",
          brandID: "1E8c635f-6B72-6cdC-95Fd-D3cfe14b3bDF",
          brandName: "Helen Martinez",
          createdAt: "2022-08-24 18:31:02",
          del: false,
          eMail: "l.dusg@fuphsrxkq.gy",
          id: -1,
          phone: "-6339270386635808",
          position: "d0Po",
          remarks: "",
          status: 0,
          userID: 308,
          wx: "",
          code: "", //	string	 必须 短信验证码
          cliamStatus: "", //1 // 无人认领 2 // 他人认领 3 // 您已认领
        };
      },
    },
  },
  watch: {
    tempData: {
      handler(v) {
        this.initTempData(v);
      },
      immediate: true,
    },
  },
  data() {
    return {
      title: "品牌认领",
      subTitle:
        "留下您的联系方式，我们专业顾问会尽快与您联系，认领成功后会为您开启品牌方专属服务。",
      timer: 0,
      hintTxt: "获取验证码",
      formData: {},
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    //申请认领
    async brandClaim() {
      const {
        applicant,
        position,
        phone,
        eMail,
        code,
        wx,
        status,
        userID,
        brandID,
        brandName,
        companyName,
      } = this.formData;
      if (!companyName) {
        this.$Message.error("请输入公司名称！");
        return;
      }
      if (!applicant) {
        this.$Message.error("请输入申请人名称！");
        return;
      }
      if (!position) {
        this.$Message.error("请输入申请人职位！");
        return;
      }
      if (!phone) {
        this.$Message.error("请输入手机号码！");
        return;
      } else if (!phoneReg.test(phone)) {
        this.$Message.error("请输入正确的手机号码！");
        return;
      }
      if (!eMail) {
        this.$Message.error("请输入企业邮箱！");
        return;
      } else if (!emailReg.test(eMail)) {
        this.$Message.error("请输入正确的企业邮箱！");
        return;
      }
      if (!code) {
        this.$Message.error("请输入短信验证码！");
        return;
      }

      let tempApply;
      this.formData.cliamStatus === 2 ? (tempApply = 2) : (tempApply = 1);
      const params = {
        userID: Number(userID), //	number	 必须 用户id	 mock: @id
        brandID: brandID, //	string	 必须 品牌id	 mock: @guid
        brandName: brandName, //	string	 必须 品牌名称	 mock: @name
        companyName: companyName,
        applicant: applicant, //	string	 必须 申请人名称	 mock: @cname
        position: position, //	string	 必须 职位	 mock: @string
        phone: phone, //	string	 必须 手机	 mock: @integer
        eMail: eMail, //	string	 必须 邮箱	 mock: @email
        wx: wx, //	string	 非必须 微信号	 mock: @string
        applyType: tempApply, //	number	 必须 申请类型 1、认领 2、申诉	 mock: @integer
        code: code,
      };
      const res = await User.setBrandClaim(params);
      if (res && res.code === 200) {
        this.$Message.success("申请成功");
        this.$emit("success");
        this.close();
      }
    },
    //获取手机验证码
    async getSmsCode(phone) {
      const params = {
        phone: phone,
      };
      const res = await User.getBrandClaimSmsCaptche(params);
      if (res && res.code === 200) {
        this.$Message.success("发送成功！");
        this.countDown();
      }
    },
    //获取验证码
    getVerCode() {
      if (this.timer !== 0) return;
      const { phone } = this.formData;
      if (!phoneReg.test(phone)) {
        this.$Message.error("请输入正确的手机号码");
        return;
      }
      if (this.timer === 0) {
        this.verCode = "";
        this.getSmsCode(phone);
      }
    },
    //倒计时
    countDown() {
      let countNum = 60;
      this.timer = setInterval(() => {
        countNum -= 1;
        this.hintTxt = `已发送（${countNum}s）`;
        if (countNum <= 0) {
          this.clearTimer();
        }
      }, 1000);
    },
    //初始化wath数据
    initTempData(v) {
      for (let i in v) {
        if (!this.formData[i]) {
          this.$set(this.formData, i, v[i]);
        } else {
          this.formData[i] = v[i];
        }
      }
      this.initTitle();
    },
    //初始化标题
    initTitle() {
      if (this.formData.cliamStatus === 2) {
        this.title = "品牌认领申诉";
        this.subTitle = "留下您的联系方式，我们专业顾问会尽快与您联系。";
      } else {
        this.title = "品牌认领";
        this.subTitle =
          "留下您的联系方式，我们专业顾问会尽快与您联系，认领成功后会为您开启品牌方专属服务。";
      }
    },
    //清除定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = 0;
      this.hintTxt = "获取验证码";
    },
    phoneRule(phone) {
      return phoneReg.test(phone);
    },
  },
  created() {},
  mounted() {},
  destroyed() {
    this.clearTimer();
  },
};
</script>
<style lang="less" scoped>
.mode-wrap {
  z-index: 1000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.4;
}
.dialog {
  width: 497px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 40px 52px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  margin-bottom: 1vh;
  .header {
    position: relative;
    margin-bottom: 32px;
    .title-wrap {
      .title {
        font-size: 28px;
        font-weight: 600;
        color: #3519fb;
        line-height: 40px;
        text-align: center;
        display: block;
      }
      .sub-title {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
        display: block;
        text-align: center;
        margin-top: 16px;
      }
    }

    .close-icon {
      position: absolute;
      top: -18px;
      right: -18px;
      width: 24px;
      height: 25px;
      cursor: pointer;
    }
  }
  .content {
    .item-wrap {
      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
        .item-title {
          width: 103px;
          margin-right: 10px;
          position: relative;
          padding-left: 10px;
          box-sizing: border-box;
          &::before {
            content: "*";
            color: red;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .no-item-title {
          &::before {
            content: "";
            color: transparent;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .input-wrap {
          position: relative;
        }
        .close-input-icon {
          width: 16px;
          height: 16px;
          position: absolute;
          right: 12px;
          top: calc(50% - 8px);
          cursor: pointer;
        }
        .input1 {
          width: 280px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #dadada;
          padding: 0 12px;
          box-sizing: border-box;
        }
        .input2 {
          width: 156px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #dadada;
          padding: 0 12px;
          box-sizing: border-box;
        }
        .ver-code {
          width: 110px;
          height: 32px;
          background: #f1f1f1;
          border-radius: 4px;
          display: inline-block;
          text-align: center;

          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.4);
          line-height: 32px;
          cursor: pointer;
          margin-left: 14px;
          &:hover {
            cursor: not-allowed;
          }
        }
        .light-ver-code {
          color: #453dfe;
          background: rgba(69, 61, 254, 0.1);
          &:hover {
            cursor: pointer;
          }
        }
        .no-hover {
          &:hover {
            color: #999999 !important;
            background: #f1f1f1 !important;
            cursor: not-allowed !important;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 8px;
    text-align: center;
    span {
      width: 100px;
      height: 32px;
      border-radius: 3px;
      line-height: 32px;
      text-align: center;

      font-size: 14px;
      font-weight: 400;
      line-height: 32px;
      margin: 0 12px;
      display: inline-block;
    }
    .cancel {
      border: 1px solid #dadada;
      cursor: pointer;
    }
    .ok {
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
