var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "modeWrap", staticClass: "mode-wrap" }, [
    _c("div", { staticClass: "mask" }),
    _c("div", { staticClass: "dialog" }, [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title-wrap" }, [
          _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _c("span", { staticClass: "sub-title" }, [
            _vm._v(_vm._s(_vm.subTitle)),
          ]),
        ]),
        _c("img", {
          staticClass: "close-icon",
          attrs: { src: require("@/assets/images/icon/close@2x.png"), alt: "" },
          on: { click: _vm.close },
        }),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "item-wrap" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title" }, [_vm._v("公司名称")]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.companyName,
                    expression: "formData.companyName",
                  },
                ],
                staticClass: "input1",
                attrs: { type: "text" },
                domProps: { value: _vm.formData.companyName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "companyName", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.companyName,
                    expression: "formData.companyName",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.companyName = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title" }, [_vm._v("您的职位")]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.position,
                    expression: "formData.position",
                  },
                ],
                staticClass: "input1",
                attrs: { type: "text", placeholder: "请输入您的职位" },
                domProps: { value: _vm.formData.position },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "position", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.position,
                    expression: "formData.position",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.position = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title" }, [_vm._v("您的姓名")]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.applicant,
                    expression: "formData.applicant",
                  },
                ],
                staticClass: "input1",
                attrs: { type: "text", placeholder: "请输入您的姓名" },
                domProps: { value: _vm.formData.applicant },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "applicant", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.applicant,
                    expression: "formData.applicant",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.applicant = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title" }, [_vm._v("手机号码")]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.phone,
                    expression: "formData.phone",
                  },
                ],
                staticClass: "input1",
                attrs: { type: "text", placeholder: "请输入手机号码" },
                domProps: { value: _vm.formData.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "phone", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.phone,
                    expression: "formData.phone",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.phone = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title" }, [_vm._v("验证码")]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.code,
                    expression: "formData.code",
                  },
                ],
                staticClass: "input2",
                attrs: { type: "text", placeholder: "请输入验证码" },
                domProps: { value: _vm.formData.code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "code", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.code,
                    expression: "formData.code",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.code = ""
                  },
                },
              }),
            ]),
            _c(
              "span",
              {
                staticClass: "ver-code",
                class: {
                  "no-hover": _vm.timer !== 0,
                  "light-ver-code":
                    _vm.phoneRule(_vm.formData.phone) && _vm.timer === 0,
                },
                on: { click: _vm.getVerCode },
              },
              [_vm._v(_vm._s(_vm.hintTxt))]
            ),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title" }, [_vm._v("企业邮箱")]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.eMail,
                    expression: "formData.eMail",
                  },
                ],
                staticClass: "input1",
                attrs: { type: "text", placeholder: "请输入企业邮箱" },
                domProps: { value: _vm.formData.eMail },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "eMail", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.eMail,
                    expression: "formData.eMail",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.eMail = ""
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", { staticClass: "item-title no-item-title" }, [
              _vm._v("微信号码"),
            ]),
            _c("div", { staticClass: "input-wrap" }, [
              _c("input", {
                directives: [
                  { name: "focusblur", rawName: "v-focusblur" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.wx,
                    expression: "formData.wx",
                  },
                ],
                staticClass: "input1",
                attrs: { type: "text", placeholder: "请输入微信号码" },
                domProps: { value: _vm.formData.wx },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "wx", $event.target.value)
                  },
                },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.wx,
                    expression: "formData.wx",
                  },
                ],
                staticClass: "close-input-icon",
                attrs: {
                  src: require("@/assets/images/icon/icon_clear.png"),
                  alt: "",
                },
                on: {
                  click: function ($event) {
                    _vm.formData.wx = ""
                  },
                },
              }),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "footer" }, [
        _c(
          "span",
          { staticClass: "cancel cancel-btn", on: { click: _vm.close } },
          [_vm._v("取消")]
        ),
        _c(
          "span",
          { staticClass: "ok main-btn", on: { click: _vm.brandClaim } },
          [_vm._v("确定")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }