<template>
  <div class="brand-claim">
    <div @click="showModeHandler">
      <slot v-bind:data="formData"></slot>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import BrandClaimForm from "./BrandClaimForm.vue";
import { User } from "@/service";
let instance = null;
import { login } from "js/utils.js";
import { emailReg } from "../../assets/javascript/utils";

export default {
  name: "brandClaim",
  props: {
    propsData: {
      type: Object,
      required: true,
      default: function () {
        return {
          brandID: "",
          brandName: "",
        };
      },
    },
    showMode: {
      type: Boolean,
      default: false,
    },
    auto: {
      //是否自动检测当前是否可认领状态
      type: Boolean,
      required: true,
      default: true,
    },
    callback: {
      type: Function,
      default: null,
    },
  },
  watch: {
    propsData: {
      handler(v) {
        this.initPropsData(v);
      },
      immediate: true,
    },
    showMode(v) {
      if (v) {
        if (v.status === 0) {
          this.$Message.error("当前品牌正在审核中！");
          return;
        }
        this.showModeHandler();
      } else {
        this.destoryMode();
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
  },
  data() {
    return {
      formData: {
        id: "", //认领id
        userID: "", //	number	 必须 用户id	 mock: @id
        brandID: "", //	string	 必须 品牌id	 mock: @guid
        companyName: "", //string 必须 公司名称
        brandName: "", //	string	 必须 品牌名称	 mock: @name
        applicant: "", //	string	 必须 申请人名称	 mock: @cname
        position: "", //	string	 必须 职位	 mock: @string
        phone: "", //	string	 必须 手机	 mock: @integer
        eMail: "", //	string	 必须 邮箱	 mock: @email
        wx: "", //	string	 非必须 微信号	 mock: @string
        applyType: "1", //	number	 必须 申请类型 1、认领 2、申诉	 mock: @integer
        code: "", //	string	 必须 短信验证码
        cliamStatus: "", //cliamStatus 1 // 无人认领 2 // 他人认领 3 // 您已认领
      },
    };
  },
  methods: {
    //初始化 表单数据
    initPropsData(v) {
      if (Object.keys(v).length > 0) {
        for (let i in v) {
          if (this.formData[i]) {
            this.$set(this.formData, i, v[i]);
          } else {
            this.formData[i] = v[i];
          }
        }
        if (this.auto) {
          this.getClaimStatus();
        }
      }
    },
    //打开认领弹框
    async showModeHandler() {
      const { cliamStatus } = this.formData;
      if (cliamStatus === 2) {
        this.$global.confirm({
          title: "提示",
          okText: "申诉",
          content: "该品牌已被他人认领，如有疑问请联系客服",
          ok: () => {
            this.createMode();
          },
          cancle: () => {
            this.$emit("close");
          },
        });
        return;
      } else if (cliamStatus === 3) {
        this.$global.confirm({
          title: "提示",
          content: "您当前已经认领了该品牌，是否要取消认领",
          ok: () => {
            this.cancelClaim();
          },
          cancle: () => {
            this.$emit("close");
          },
        });
        return;
      }

      this.createMode();
    },
    //取消认领
    async cancelClaim() {
      const params = {
        id: this.formData.id,
      };
      const res = await User.cancelBrandClaim(params);
      if (res && res.code === 200) {
        this.$Message.success("取消认领成功");
        this.formData.cliamStatus = 1;
      }
    },
    //创建认领弹框
    createMode() {
      if (instance) return;
      const { userID } = this.userInfo;
      let that = this;
      if (!userID) {
        this.$global.confirm({
          title: "提示",
          content: "请先登录",
          ok: () => {
            login();
          },
        });
        return;
      }
      instance = new Vue({
        el: document.createElement("div"),
        render: (h) =>
          h(BrandClaimForm, {
            props: { tempData: this.formData },
            on: {
              close: that.destoryMode,
              success: that.successHandler,
            },
          }),
      });
      document.body.appendChild(instance.$el);
    },
    //销毁认领弹框
    destoryMode() {
      if (instance) {
        if (instance.$el && instance.$el.parentNode) {
          instance.$el.parentNode.removeChild(instance.$el);
          instance.$destroy();
        }
        instance = null;
      }
      this.$emit("close");
    },
    //获取当前认领状态
    async getClaimStatus() {
      const { brandID } = this.formData;
      if (!brandID) {
        this.$Message.error("获取当前品牌id异常！");
        return;
      }
      if (!this.userInfo.userID) {
        return;
      }
      const params = {
        brandID: brandID,
      };
      const res = await User.getBrandStatus(params);
      if (res && res.code === 200) {
        if (res.data.items.info) {
          const { companyName, code, name } = res.data.items.info;
          const {
            id,
            applicant,
            applyType,
            cliamStatus,
            wx,
            eMail,
            phone,
            position,
          } = res.data.items;
          this.formData.id = id;
          this.formData.userID = Number(this.userInfo.userID);
          this.formData.companyName = companyName;
          this.formData.brandName = name;
          this.formData.brandID = code;
          this.formData.applicant = applicant;
          this.formData.position = position;
          this.formData.phone = phone;
          this.formData.eMail = eMail;
          this.formData.wx = wx;
          this.formData.applyType = applyType;
          this.formData.cliamStatus = cliamStatus;
        }
      }
    },
    //领取成功回调
    successHandler() {
      this.formData.cliamStatus = 3;
      if (this.callback) {
        this.callback();
      }
    },
  },
  mounted() {},
  created() {
    if (this.auto) {
      //获取认领状态接口数据
    }
    //获取当前认领状态
  },
};
</script>
<style lang="less" scoped>
.brand-claim {
  .mode-wrap {
    z-index: 1000;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.4;
  }

  .dialog {
    width: 497px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 40px 52px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    margin-bottom: 100px;
    .header {
      position: relative;
      margin-bottom: 32px;
      .title-wrap {
        .title {
          font-size: 28px;
          font-weight: 600;
          color: #3519fb;
          line-height: 40px;
          text-align: center;
          display: block;
        }
        .sub-title {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          display: block;
          text-align: center;
          margin-top: 16px;
        }
      }

      .close-icon {
        position: absolute;
        top: -18px;
        right: -18px;
        width: 24px;
        height: 25px;
        cursor: pointer;
      }
    }
    .content {
      .item-wrap {
        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 24px;
          .item-title {
            width: 103px;
            margin-right: 10px;
            position: relative;
            padding-left: 10px;
            box-sizing: border-box;
            &::before {
              content: "*";
              color: red;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .no-item-title {
            &::before {
              content: "";
              color: transparent;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          .input-wrap {
            position: relative;
          }
          .close-input-icon {
            width: 16px;
            height: 16px;
            position: absolute;
            right: 12px;
            top: calc(50% - 8px);
            cursor: pointer;
          }
          .input1 {
            width: 280px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #dadada;
            padding: 0 12px;
            box-sizing: border-box;
          }
          .input2 {
            width: 156px;
            height: 32px;
            border-radius: 2px;
            border: 1px solid #dadada;
            padding: 0 12px;
            box-sizing: border-box;
          }
          .ver-code {
            width: 110px;
            height: 32px;
            background: #f1f1f1;
            border-radius: 4px;
            display: inline-block;
            text-align: center;

            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.4);
            line-height: 32px;
            cursor: pointer;
            margin-left: 14px;
            &:hover {
              color: #453dfe;
              background: rgba(69, 61, 254, 0.1);
            }
          }
          .no-hover {
            &:hover {
              color: #999999 !important;
              background: #f1f1f1 !important;
              cursor: not-allowed !important;
            }
          }
        }
      }
    }
    .footer {
      margin-top: 8px;
      text-align: center;
      span {
        width: 100px;
        height: 32px;
        border-radius: 3px;
        line-height: 32px;
        text-align: center;

        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        margin: 0 12px;
        display: inline-block;
      }
      .cancel {
        border: 1px solid #dadada;
        color: #666666;
      }
      .ok {
        background: #3519fb;
        color: #ffffff;
      }
    }
  }
}
</style>
