var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "brand-claim" }, [
    _c(
      "div",
      { on: { click: _vm.showModeHandler } },
      [_vm._t("default", null, { data: _vm.formData })],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }